import React from 'react';

const ContactIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7073 13.7754H11.1234C10.8206 13.7754 10.5749 13.5304 10.5749 13.2276C10.5749 13.1156 10.62 13.0074 10.7004 12.9293C11.04 12.5994 11.3256 12.1553 11.5322 11.6766C11.5743 11.7074 11.6194 11.73 11.6705 11.73C11.9981 11.73 12.3844 11.0056 12.3844 10.5119C12.3844 10.0182 12.3385 9.61769 12.0101 9.61769C11.9711 9.61769 11.9305 9.62445 11.8884 9.63497C11.8651 8.29592 11.527 6.62549 9.48231 6.62549C7.34899 6.62549 7.10027 8.29292 7.07622 9.63047C7.04616 9.62445 7.01611 9.61769 6.9883 9.61769C6.65993 9.61769 6.61484 10.0182 6.61484 10.5119C6.61484 11.0056 6.99958 11.73 7.32795 11.73C7.36853 11.73 7.4061 11.7187 7.44142 11.6984C7.64731 12.1696 7.9291 12.6046 8.26349 12.9315C8.34389 13.0097 8.38898 13.1164 8.38898 13.2291C8.38898 13.5319 8.14326 13.7776 7.84043 13.7776H7.25581C6.00693 13.7776 4.99475 14.7898 4.99475 16.0387V16.6654C4.99475 17.0591 5.31411 17.3792 5.70861 17.3792H13.2545C13.6483 17.3792 13.9676 17.0591 13.9676 16.6654V16.0387C13.9684 14.7883 12.9562 13.7754 11.7073 13.7754Z"
      fill="#E74D3C"
    />
    <path
      d="M18.477 7.57227H12.1492C12.3318 7.97654 12.4625 8.47399 12.5166 9.08941C12.6519 9.17282 12.7533 9.30733 12.836 9.46964H18.477C18.77 9.46964 19.006 9.23294 19.006 8.94063V8.10128C19.006 7.80972 18.77 7.57227 18.477 7.57227Z"
      fill="#E74D3C"
    />
    <path
      d="M18.477 11.0295H12.9652C12.8157 11.5946 12.4475 12.186 11.9373 12.3438C11.9222 12.3716 11.9049 12.3956 11.8892 12.4234V12.9269H18.4762C18.7685 12.9269 19.0052 12.6902 19.0052 12.3971V11.5585C19.006 11.2662 18.77 11.0295 18.477 11.0295Z"
      fill="#E74D3C"
    />
    <path
      d="M18.477 14.4868H14.1743C14.4584 14.9362 14.6252 15.4659 14.6252 16.0363V16.3842H18.477C18.7701 16.3842 19.006 16.1475 19.006 15.8552V15.0158C19.006 14.7235 18.7701 14.4868 18.477 14.4868Z"
      fill="#E74D3C"
    />
    <path
      d="M20.7508 2.29712H3.24995C1.45477 2.29712 0 3.75189 0 5.54706V18.4537C0 20.2496 1.45477 21.7036 3.24995 21.7036H20.7508C22.546 21.7036 24 20.2488 24 18.4537V5.54706C24 3.75189 22.546 2.29712 20.7508 2.29712ZM22.4663 18.4537C22.4663 19.3997 21.6969 20.1692 20.7508 20.1692H3.24995C2.30389 20.1692 1.53443 19.3997 1.53443 18.4537V5.54706C1.53443 4.60101 2.30389 3.83154 3.24995 3.83154H20.7508C21.6969 3.83154 22.4663 4.60101 22.4663 5.54706V18.4537Z"
      fill="#E74D3C"
    />
  </svg>
);

export default ContactIcon;
