import React from 'react';

const DesktopIcon = () => (
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.2952 0H0.704813C0.315563 0 0 0.315562 0 0.704812V13.6841C0 14.0734 0.315563 14.3889 0.704813 14.3889H9.51469V15.6791H7.61325C7.224 15.6791 6.90844 15.9946 6.90844 16.3839C6.90844 16.7731 7.224 17.0887 7.61325 17.0887H16.3867C16.776 17.0887 17.0915 16.7731 17.0915 16.3839C17.0915 15.9946 16.776 15.6791 16.3867 15.6791H14.4853V14.3889H23.2952C23.6844 14.3889 24 14.0734 24 13.6841V0.704812C24 0.315562 23.6844 0 23.2952 0V0ZM13.0757 15.6791H10.9243V14.389H13.0757V15.6791ZM22.5904 12.9794H1.40963V1.40967H22.5904V12.9794Z"
      fill="#E74D3C"
    />
  </svg>
);

export default DesktopIcon;
