import React from 'react';

const DashboardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.352109 5.13365C0.546567 5.13365 0.704219 4.976 0.704219 4.78154V4.14338H21.5303V7.47921C21.5303 7.67367 21.6879 7.83132 21.8824 7.83132C22.0769 7.83132 22.2345 7.67367 22.2345 7.47921V1.48364C22.2345 0.668106 21.571 0.00463867 20.7555 0.00463867H1.47895C0.663468 0.00463867 0 0.668106 0 1.48364V4.78154C0 4.976 0.157651 5.13365 0.352109 5.13365ZM1.47895 0.708857H20.7555C21.1827 0.708857 21.5303 1.05641 21.5303 1.48364V3.43916H0.704219V1.48364C0.704219 1.05641 1.05177 0.708857 1.47895 0.708857Z"
      fill="#E74D3C"
    />
    <path
      d="M7.76016 21.5348H1.47895C1.05173 21.5348 0.704219 21.1872 0.704219 20.7601V6.28204C0.704219 6.08758 0.546567 5.92993 0.352109 5.92993C0.157651 5.92993 0 6.08758 0 6.28204V20.7601C0 21.5756 0.663468 22.239 1.47895 22.239H7.76016C7.95462 22.239 8.11227 22.0814 8.11227 21.8869C8.11227 21.6925 7.95462 21.5348 7.76016 21.5348Z"
      fill="#E74D3C"
    />
    <path
      d="M22.3456 8.53564H10.4709C9.55863 8.53564 8.81653 9.2778 8.81653 10.19V14.7648C8.81653 14.9593 8.97418 15.1169 9.16864 15.1169C9.3631 15.1169 9.52075 14.9593 9.52075 14.7648V12.4018H23.2958V22.3408C23.2958 22.8647 22.8696 23.2909 22.3457 23.2909H10.4709C9.94699 23.2909 9.52075 22.8647 9.52075 22.3408V16.2654C9.52075 16.071 9.3631 15.9133 9.16864 15.9133C8.97418 15.9133 8.81653 16.071 8.81653 16.2654V22.3408C8.81653 23.2531 9.55868 23.9952 10.4709 23.9952H22.3456C23.2579 23.9952 24 23.253 24 22.3408V10.19C24 9.2778 23.2579 8.53564 22.3456 8.53564ZM9.52075 11.6976V10.19C9.52075 9.6661 9.94699 9.23986 10.4709 9.23986H22.3456C22.8695 9.23986 23.2958 9.6661 23.2958 10.19V11.6976H9.52075Z"
      fill="#E74D3C"
    />
    <path
      d="M2.15777 2.40857H2.42219C2.61664 2.40857 2.77429 2.25091 2.77429 2.05646C2.77429 1.862 2.61664 1.70435 2.42219 1.70435H2.15777C1.96332 1.70435 1.80566 1.862 1.80566 2.05646C1.80566 2.25091 1.96332 2.40857 2.15777 2.40857Z"
      fill="#E74D3C"
    />
    <path
      d="M4.60675 2.40857H4.87116C5.06562 2.40857 5.22327 2.25091 5.22327 2.05646C5.22327 1.862 5.06562 1.70435 4.87116 1.70435H4.60675C4.41229 1.70435 4.25464 1.862 4.25464 2.05646C4.25464 2.25091 4.41229 2.40857 4.60675 2.40857Z"
      fill="#E74D3C"
    />
    <path
      d="M7.05597 2.40857H7.32038C7.51483 2.40857 7.67248 2.25091 7.67248 2.05646C7.67248 1.862 7.51483 1.70435 7.32038 1.70435H7.05597C6.86151 1.70435 6.70386 1.862 6.70386 2.05646C6.70386 2.25091 6.86151 2.40857 7.05597 2.40857Z"
      fill="#E74D3C"
    />
    <path
      d="M20.5554 17.1439H19.0727C18.8782 17.1439 18.7206 17.3015 18.7206 17.496C18.7206 17.6904 18.8782 17.8481 19.0727 17.8481H20.5554C21.1472 17.8481 21.6286 17.3666 21.6286 16.7749V15.28C21.6286 14.6882 21.1472 14.2068 20.5554 14.2068H12.261C11.6692 14.2068 11.1877 14.6882 11.1877 15.28V16.7749C11.1877 17.3666 11.6692 17.8481 12.261 17.8481H17.5722C17.7667 17.8481 17.9243 17.6904 17.9243 17.496C17.9243 17.3015 17.7667 17.1439 17.5722 17.1439H12.261C12.0575 17.1439 11.892 16.9783 11.892 16.7749V15.28C11.892 15.0765 12.0575 14.911 12.261 14.911H20.5554C20.7589 14.911 20.9244 15.0765 20.9244 15.28V16.7749C20.9244 16.9783 20.7589 17.1439 20.5554 17.1439Z"
      fill="#E74D3C"
    />
    <path
      d="M3.70208 7.04455H18.6321C18.8266 7.04455 18.9842 6.8869 18.9842 6.69244C18.9842 6.49798 18.8266 6.34033 18.6321 6.34033H3.70208C3.27195 6.34033 2.922 6.69028 2.922 7.12042V11.5115C2.922 11.9416 3.27195 12.2915 3.70208 12.2915H6.87421C7.06867 12.2915 7.22632 12.1339 7.22632 11.9394C7.22632 11.745 7.06867 11.5873 6.87421 11.5873H3.70208C3.66025 11.5873 3.62622 11.5533 3.62622 11.5115V7.12042C3.62622 7.07859 3.66025 7.04455 3.70208 7.04455Z"
      fill="#E74D3C"
    />
    <path
      d="M6.86233 13.7842H3.27411C3.07965 13.7842 2.922 13.9418 2.922 14.1363C2.922 14.3308 3.07965 14.4884 3.27411 14.4884H6.86233C7.05679 14.4884 7.21444 14.3308 7.21444 14.1363C7.21444 13.9418 7.05679 13.7842 6.86233 13.7842Z"
      fill="#E74D3C"
    />
    <path
      d="M6.86233 16.4238H3.27411C3.07965 16.4238 2.922 16.5815 2.922 16.7759C2.922 16.9704 3.07965 17.1281 3.27411 17.1281H6.86233C7.05679 17.1281 7.21444 16.9704 7.21444 16.7759C7.21444 16.5815 7.05679 16.4238 6.86233 16.4238Z"
      fill="#E74D3C"
    />
    <path
      d="M11.5399 20.8886H21.2766C21.4711 20.8886 21.6287 20.7309 21.6287 20.5364C21.6287 20.342 21.4711 20.1843 21.2766 20.1843H11.5399C11.3454 20.1843 11.1877 20.342 11.1877 20.5364C11.1877 20.7309 11.3454 20.8886 11.5399 20.8886Z"
      fill="#E74D3C"
    />
  </svg>
);

export default DashboardIcon;
