import React from 'react';

const Ecommerce = () => (
  <div>
    <img
      className="rounded-lg border-none"
      src={require('../assets/ecommerce.jpg')}
      alt="logo"
    />
  </div>
);

export default Ecommerce;
