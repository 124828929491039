import React from 'react';

const BusinessPlan = () => (
  <div>
    <img
      className="rounded-lg border-none"
      src={require('../assets/business-plan.jpg')}
      alt="logo"
    />
  </div>
);

export default BusinessPlan;
