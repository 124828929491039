import React from 'react';

const CustomSoftware = () => (
  <div>
    <img
      className="rounded-lg border-none"
      src={require('../assets/adhoc-software.jpg')}
      alt="logo"
    />
  </div>
);

export default CustomSoftware;
