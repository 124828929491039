import React from 'react';

const BusinessConsulting = () => (
  <div>
    <img
      className="rounded-lg border-none"
      src={require('../assets/business-consulting.jpg')}
      alt="logo"
    />
  </div>
);

export default BusinessConsulting;
