import React from 'react';

const CardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9964 8.22679H19.5542V4.7795C19.5542 3.67471 18.6554 2.77588 17.5506 2.77588H2.00362C0.898828 2.77593 0 3.67475 0 4.77955V13.7695C0 14.8743 0.898828 15.7731 2.00362 15.7731H4.44591V19.2206C4.44591 20.3254 5.34473 21.2242 6.44953 21.2242H21.9964C23.1012 21.2242 24 20.3254 24 19.2206V10.2304C24 9.12561 23.1012 8.22679 21.9964 8.22679ZM1.14492 4.77955C1.14492 4.30607 1.53014 3.92085 2.00362 3.92085H17.5506C18.024 3.92085 18.4093 4.30607 18.4093 4.77955V5.57882H1.14492V4.77955ZM4.44591 10.2304V14.6282H2.00362C1.53014 14.6282 1.14492 14.243 1.14492 13.7695V9.37527H4.63805C4.515 9.63477 4.44591 9.92469 4.44591 10.2304ZM1.14492 8.23035V6.72379H18.4093V8.22679H6.44953C6.41081 8.22679 6.37247 8.22814 6.33431 8.23035H1.14492ZM21.9964 20.0793H6.44953C5.97605 20.0793 5.59083 19.6941 5.59083 19.2206V10.2304C5.59083 9.78055 5.93873 9.41108 6.37945 9.37527H18.9816C19.0027 9.37527 19.0235 9.37396 19.044 9.37171H21.9963C22.4698 9.37171 22.855 9.75693 22.855 10.2304V19.2206H22.8551C22.8551 19.6941 22.4699 20.0793 21.9964 20.0793Z"
      fill="#E74D3C"
    />
    <path
      d="M15.9474 11.9659C15.2962 11.9659 14.6974 12.1931 14.225 12.5717C13.7406 12.1815 13.1371 11.9656 12.4986 11.9656C10.9768 11.9656 9.73877 13.2036 9.73877 14.7254C9.73877 16.2472 10.9768 17.4853 12.4986 17.4853C13.1371 17.4853 13.7406 17.2693 14.225 16.8792C14.6974 17.2578 15.2962 17.4851 15.9474 17.4851C17.4691 17.4851 18.707 16.2471 18.707 14.7255C18.707 13.2038 17.4691 11.9659 15.9474 11.9659ZM13.4978 15.9938C13.2161 16.2168 12.8673 16.3403 12.4986 16.3403C11.6081 16.3404 10.8836 15.6159 10.8836 14.7255C10.8836 13.835 11.6081 13.1105 12.4986 13.1105C12.8673 13.1105 13.2161 13.2341 13.4978 13.457C13.3002 13.8371 13.1878 14.2683 13.1878 14.7254C13.1878 15.1826 13.3002 15.6137 13.4978 15.9938ZM15.9474 16.3401C15.0571 16.3401 14.3328 15.6158 14.3328 14.7254C14.3328 13.8351 15.0572 13.1108 15.9474 13.1108C16.8377 13.1108 17.562 13.8352 17.562 14.7254C17.562 15.6158 16.8377 16.3401 15.9474 16.3401Z"
      fill="#E74D3C"
    />
  </svg>
);

export default CardIcon;
