import React from 'react';
import TextLoop from 'react-text-loop';
import SplitSection from '../../components/SplitSection';
import BusinessPlan from '../../svg/BusinessPlan';
import PlanningIcon from '../../svg/PlanningIcon';
import ImprovementIcon from '../../svg/ImprovementIcon';
import ProcessIcon from '../../svg/ProcessIcon';
import BusinessConsulting from '../../svg/BusinessConsulting';
import CustomerExperience from '../../svg/CustomerExperience';
import LandingIcon from '../../svg/LandingIcon';
import ContactIcon from '../../svg/ContactIcon';
import BotIcon from '../../svg/BotIcon';
import Website from '../../svg/Website';
import CardIcon from '../../svg/CardIcon';
import DashboardIcon from '../../svg/DashboardIcon';
import Ecommerce from '../../svg/Ecommerce';
import AndroidIcon from '../../svg/AndroidIcon';
import IOSIcon from '../../svg/IOSIcon';
import MobileApp from '../../svg/MobileApp';
import DesktopIcon from '../../svg/DesktopIcon';
import CustomIcon from '../../svg/CustomIcon';
import CustomSoftware from '../../svg/CustomSoftware';
import LangProvider from '../../lang/lang-provider';
import PlanIcon from '../../svg/PlanIcon';
import JourneyIcon from '../../svg/JourneyIcon';
import SatisfactionIcon from '../../svg/SatisfactionIcon';

const _ = LangProvider.getConstants();

const Services = () => (
  <>
    <div className="text-center pt-8 pb-8 pl-2 pr-2 mt-20 services-container">
      <h2 className="text-5xl sm:text-2xl md:text-5xl lg:text-5xl p-0 m-0 text-primary">
        <TextLoop interval={3500}>
          <span>{_.services.headerTechnology1}</span>
          <span>{_.services.headerTechnology2}</span>
          <span>{_.services.headerTechnology3}</span>
          <span>{_.services.headerTechnology4}</span>
        </TextLoop>
      </h2>
      <h2 className="text-2xl md:text-3xl lg:text-5xl font-light p-0 m-0 text-secondary-blueDarker2">
        <TextLoop interval={3500}>
          <span>{_.services.headerTechnologyItem1}</span>
          <span>{_.services.headerTechnologyItem2}</span>
          <span>{_.services.headerTechnologyItem3}</span>
          <span>{_.services.headerTechnologyItem4}</span>
        </TextLoop>
      </h2>
      <p className="text-2xl mt-6 lg:pl-56 lg:pr-56 text-gray-600">
        {_.services.headerTechnologyDescription}
      </p>
    </div>
    <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
      <SplitSection
        primarySlot={
          <div className="lg:pr-10">
            <h3 className="text-4xl lg:text-5xl font-bold leading-tight">
              {_.services.titleCustomSoftwareService}
            </h3>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <DesktopIcon />
              <span className="ml-4">{_.services.customSoftwareServiceItem1}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.customSoftwareServiceItem1_description}
            </p>
          </div>
        }
        secondarySlot={<CustomSoftware />}
      />
    </div>
    <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-20">
            <h3 className="text-4xl lg:text-5xl font-bold leading-tight">
              {_.services.titleMobileService}
            </h3>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <AndroidIcon />
              <span className="ml-4">{_.services.mobileServiceItem1}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.mobileServiceItem1_description}
            </p>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <IOSIcon />
              <span className="ml-4">{_.services.mobileServiceItem2}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.mobileServiceItem2_description}
            </p>
          </div>
        }
        secondarySlot={<MobileApp />}
      />
    </div>
    <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
      <SplitSection
        primarySlot={
          <div className="lg:pr-10">
            <h3 className="text-4xl lg:text-5xl font-bold leading-tight">
              {_.services.titleEcommerceService}
            </h3>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <CardIcon />
              <span className="ml-4">{_.services.ecommerceServiceItem1}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.ecommerceServiceItem1_description}
            </p>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <DashboardIcon />
              <span className="ml-4">{_.services.ecommerceServiceItem2}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.ecommerceServiceItem2_description}
            </p>
          </div>
        }
        secondarySlot={<Ecommerce />}
      />
    </div>
    <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-20">
            <h3 className="text-4xl lg:text-5xl font-bold leading-tight">
              {_.services.titleWebsiteService}
            </h3>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <LandingIcon />
              <span className="ml-4">{_.services.websiteServiceItem1}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.websiteServiceItem1_description}
            </p>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <ContactIcon />
              <span className="ml-4">{_.services.websiteServiceItem2}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.websiteServiceItem2_description}
            </p>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <BotIcon />
              <span className="ml-4">{_.services.websiteServiceItem3}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.websiteServiceItem3_description}
            </p>
          </div>
        }
        secondarySlot={<Website />}
      />
    </div>
    <div className="text-center pt-8 pb-8 pl-2 pr-2 services-container">
      <h2 className="text-5xl sm:text-2xl md:text-5xl lg:text-5xl p-0 m-0 text-primary">
        <TextLoop interval={3500}>
          <span>{_.services.headerBusiness1}</span>
          <span>{_.services.headerBusiness2}</span>
          <span>{_.services.headerBusiness3}</span>
        </TextLoop>
      </h2>
      <h2 className="text-2xl md:text-3xl lg:text-5xl font-light p-0 m-0 text-secondary-blueDarker2">
        <TextLoop interval={3500}>
          <span>{_.services.headerBusinessItem1}</span>
          <span>{_.services.headerBusinessItem2}</span>
          <span>{_.services.headerBusinessItem3}</span>
        </TextLoop>
      </h2>
      <p className="text-2xl mt-6 lg:pl-56 lg:pr-56 text-gray-600">
        {_.services.headerBusinessDescription}
      </p>
    </div>
    <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
      <SplitSection
        primarySlot={
          <div className="lg:pr-10">
            <h3 className="text-4xl lg:text-5xl font-bold leading-tight">
              {_.services.titleBusinessPlanService}
            </h3>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <PlanIcon />
              <span className="ml-4"> {_.services.businessPlanServiceItem1}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.businessPlanServiceItem1_description}
            </p>
          </div>
        }
        secondarySlot={<BusinessPlan />}
      />
    </div>
    <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-20">
            <h3 className="text-4xl lg:text-5xl font-bold leading-tight">
              {_.services.titleBusinessConsultingService}
            </h3>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <PlanningIcon />
              <span className="ml-4">{_.services.businessConsultingServiceItem1}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.businessConsultingServiceItem1_description}
            </p>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <ProcessIcon />
              <span className="ml-4">{_.services.businessConsultingServiceItem2}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.businessConsultingServiceItem2_description}
            </p>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <ImprovementIcon />
              <span className="ml-4">{_.services.businessConsultingServiceItem3}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.businessConsultingServiceItem3_description}
            </p>
          </div>
        }
        secondarySlot={<BusinessConsulting />}
      />
    </div>
    <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
      <SplitSection
        primarySlot={
          <div className="lg:pr-10">
            <h3 className="text-4xl lg:text-5xl font-bold leading-tight">
              {_.services.titleCustomerExperienceService}
            </h3>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <JourneyIcon />
              <span className="ml-4">{_.services.customerExperienceServiceItem1}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.customerExperienceServiceItem1_description}
            </p>
            <p className="mt-8 text-xl text-primary font-semibold leading-relaxed inline-flex items-center">
              <SatisfactionIcon />
              <span className="ml-4">{_.services.customerExperienceServiceItem2}</span>
            </p>
            <p className="text-lg text-gray-600">
              {_.services.customerExperienceServiceItem2_description}
            </p>
          </div>
        }
        secondarySlot={<CustomerExperience />}
      />
    </div>
  </>
);

export default Services;
