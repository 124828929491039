import React from 'react';

const AndroidIcon = () => (
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0874 7.92334H4.91254C4.64171 7.92334 4.42218 8.14287 4.42218 8.4137V18.6233C4.42218 18.8942 4.64171 19.1137 4.91254 19.1137H7.08303V22.5544C7.08303 23.3527 7.73018 23.9999 8.52854 23.9999C9.32684 23.9999 9.97404 23.3527 9.97404 22.5544V19.1137H11.9958V22.5544C11.9958 23.3527 12.643 23.9999 13.4413 23.9999C14.2396 23.9999 14.8868 23.3527 14.8868 22.5544V19.1137H17.0875C17.3583 19.1137 17.5778 18.8942 17.5778 18.6233V8.4137C17.5778 8.14287 17.3582 7.92334 17.0874 7.92334Z"
      fill="#E74D3C"
    />
    <path
      d="M2.36097 7.92114C1.56267 7.92114 0.915466 8.56829 0.915466 9.36665V15.2377C0.915466 16.036 1.56261 16.6832 2.36097 16.6832C3.15927 16.6832 3.80648 16.0361 3.80648 15.2377V9.36665C3.80642 8.56835 3.15927 7.92114 2.36097 7.92114Z"
      fill="#E74D3C"
    />
    <path
      d="M19.639 7.92114C18.8407 7.92114 18.1935 8.56829 18.1935 9.36665V15.2377C18.1935 16.036 18.8406 16.6832 19.639 16.6832C20.4373 16.6832 21.0845 16.0361 21.0845 15.2377V9.36665C21.0845 8.56835 20.4373 7.92114 19.639 7.92114Z"
      fill="#E74D3C"
    />
    <path
      d="M5.01107 7.20744H16.9601C17.2719 7.20744 17.5044 6.92029 17.4403 6.6152C17.0451 4.73138 15.8457 3.14384 14.2183 2.22674L15.2397 0.383656C15.3089 0.258874 15.2638 0.101618 15.139 0.0324888C15.0139 -0.0368151 14.8569 0.00843866 14.7878 0.13322L13.7586 1.99019C12.9156 1.599 11.9761 1.38052 10.9856 1.38052C9.99502 1.38052 9.05555 1.599 8.21258 1.99019L7.18336 0.133162C7.11417 0.00832248 6.95692 -0.0366989 6.8322 0.0324307C6.70741 0.10156 6.66233 0.258816 6.73146 0.383597L7.75296 2.22669C6.12551 3.14384 4.92614 4.73133 4.53088 6.6152C4.4668 6.92029 4.69929 7.20744 5.01107 7.20744ZM14.5511 4.46068C14.5511 4.76752 14.3023 5.01633 13.9954 5.01633C13.6886 5.01633 13.4398 4.76758 13.4398 4.46068C13.4398 4.15383 13.6885 3.90502 13.9954 3.90502C14.3023 3.90502 14.5511 4.15383 14.5511 4.46068ZM7.97568 3.90502C8.28252 3.90502 8.53133 4.15378 8.53133 4.46068C8.53133 4.76752 8.28258 5.01633 7.97568 5.01633C7.66884 5.01633 7.42003 4.76758 7.42003 4.46068C7.42003 4.15383 7.66878 3.90502 7.97568 3.90502Z"
      fill="#E74D3C"
    />
  </svg>
);

export default AndroidIcon;
