import React from 'react';

const LandingIcon = () => (
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.95166 12.7717H20.8917C21.0859 12.7717 21.2433 12.6144 21.2433 12.4202V10.4416C21.2433 10.2474 21.0859 10.0901 20.8917 10.0901C20.6975 10.0901 20.5402 10.2474 20.5402 10.4416V12.0686H7.95166C7.7575 12.0686 7.6001 12.226 7.6001 12.4202C7.6001 12.6144 7.7575 12.7717 7.95166 12.7717Z"
      fill="#E74D3C"
    />
    <path
      d="M21.2434 1.77368C21.2434 1.57948 21.086 1.42212 20.8918 1.42212H3.10827C2.91412 1.42212 2.75671 1.57948 2.75671 1.77368V12.4201C2.75671 12.6143 2.91412 12.7716 3.10827 12.7716H6.29259C6.48674 12.7716 6.64415 12.6143 6.64415 12.4201C6.64415 12.2259 6.48674 12.0685 6.29259 12.0685H3.45983V2.12524H20.5402V8.78239C20.5402 8.97659 20.6976 9.13394 20.8918 9.13394C21.086 9.13394 21.2434 8.97659 21.2434 8.78239V1.77368Z"
      fill="#E74D3C"
    />
    <path
      d="M23.8278 13.8073C23.6705 13.6036 23.4327 13.4867 23.1753 13.4867H22.5993C22.6394 13.375 22.6614 13.2546 22.6614 13.1292V1.06478C22.6614 0.479925 22.1857 0.00415039 21.6008 0.00415039H9.17477C8.98062 0.00415039 8.82322 0.161508 8.82322 0.355708C8.82322 0.549909 8.98062 0.707266 9.17477 0.707266H21.6008C21.7979 0.707266 21.9583 0.86767 21.9583 1.06478V13.1293C21.9583 13.3264 21.7979 13.4868 21.6008 13.4868H2.39912C2.20197 13.4868 2.04161 13.3264 2.04161 13.1293V1.06478C2.04161 0.86767 2.20202 0.707266 2.39912 0.707266H7.51561C7.70976 0.707266 7.86717 0.549909 7.86717 0.355708C7.86717 0.161508 7.70976 0.00415039 7.51561 0.00415039H2.39912C1.81427 0.00415039 1.3385 0.479925 1.3385 1.06478V13.1293C1.3385 13.2546 1.36053 13.375 1.40065 13.4868H0.824659C0.567272 13.4868 0.329478 13.6036 0.172214 13.8073C0.0149505 14.0111 -0.0378769 14.2708 0.0272785 14.5198L0.160121 15.0273C0.309416 15.5975 0.825362 15.9958 1.41486 15.9958H22.5852C23.1747 15.9958 23.6907 15.5975 23.84 15.0273L23.9728 14.5198C24.0379 14.2707 23.985 14.0111 23.8278 13.8073ZM0.840268 14.8492L0.707426 14.3417C0.694302 14.2916 0.715161 14.2546 0.728754 14.237C0.742348 14.2194 0.772863 14.1899 0.824659 14.1899H9.06724V14.8143C9.06724 14.9845 9.10371 15.1464 9.16882 15.2927H1.41481C1.14491 15.2927 0.908658 15.1103 0.840268 14.8492ZM10.2488 15.2927C9.98495 15.2927 9.77031 15.0781 9.77031 14.8143V14.1899H14.2296V14.8143C14.2296 15.0781 14.015 15.2927 13.7512 15.2927H10.2488ZM23.2925 14.3417L23.1597 14.8492C23.0913 15.1103 22.8551 15.2927 22.5852 15.2927H14.8312C14.8963 15.1464 14.9327 14.9845 14.9327 14.8143V14.1899H23.1753C23.2271 14.1899 23.2576 14.2194 23.2712 14.237C23.2848 14.2546 23.3056 14.2916 23.2925 14.3417Z"
      fill="#E74D3C"
    />
    <path
      d="M4.48205 2.86548H4.47717C4.28302 2.86548 4.12561 3.02284 4.12561 3.21704C4.12561 3.41124 4.28302 3.5686 4.47717 3.5686H4.48205C4.6762 3.5686 4.83361 3.41124 4.83361 3.21704C4.83361 3.02284 4.67625 2.86548 4.48205 2.86548Z"
      fill="#E74D3C"
    />
    <path
      d="M4.48205 3.99805H4.47717C4.28302 3.99805 4.12561 4.15541 4.12561 4.34961C4.12561 4.54381 4.28302 4.70117 4.47717 4.70117H4.48205C4.6762 4.70117 4.83361 4.54381 4.83361 4.34961C4.83361 4.15541 4.67625 3.99805 4.48205 3.99805Z"
      fill="#E74D3C"
    />
    <path
      d="M4.48059 5.13062C4.47947 5.13062 4.47834 5.13062 4.47722 5.13062C4.28461 5.13062 4.12758 5.28586 4.12575 5.4789C4.12392 5.67305 4.27983 5.83191 4.47398 5.83374L4.47886 5.83379H4.48223C4.67484 5.83379 4.83187 5.67854 4.8337 5.48551C4.83553 5.29135 4.67962 5.13249 4.48547 5.13066L4.48059 5.13062Z"
      fill="#E74D3C"
    />
    <path
      d="M9.05257 6.11558C9.05257 6.00828 9.00439 5.91242 8.92864 5.84792C8.94373 5.78614 8.95264 5.72197 8.95264 5.65559V3.89422C8.95264 3.82789 8.94378 3.76372 8.92868 3.70198C9.00443 3.63748 9.05262 3.54162 9.05262 3.43433C9.05262 3.24012 8.89522 3.08276 8.70106 3.08276H6.7677C6.57354 3.08276 6.41614 3.24012 6.41614 3.43433C6.41614 3.54162 6.46433 3.63748 6.54008 3.70198C6.52498 3.76372 6.51612 3.82789 6.51612 3.89422V5.65559C6.51612 5.72197 6.52503 5.78614 6.54012 5.84792C6.46437 5.91242 6.41618 6.00828 6.41618 6.11558C6.41618 6.30978 6.57359 6.46714 6.76775 6.46714H8.70106C8.89517 6.46714 9.05257 6.30973 9.05257 6.11558ZM8.14118 3.78589C8.2009 3.78589 8.24951 3.83445 8.24951 3.89422V5.65559C8.24951 5.71531 8.2009 5.76392 8.14118 5.76392H7.32753C7.26781 5.76392 7.2192 5.71536 7.2192 5.65559V3.89422C7.2192 3.8345 7.26781 3.78589 7.32753 3.78589H8.14118Z"
      fill="#E74D3C"
    />
    <path
      d="M9.86499 6.11553C9.86499 6.30973 10.0224 6.46709 10.2166 6.46709H12.1499C12.3441 6.46709 12.5014 6.30973 12.5014 6.11553C12.5014 6.00823 12.4532 5.91237 12.3775 5.84787C12.3926 5.78609 12.4015 5.72192 12.4015 5.65555V3.89417C12.4015 3.82784 12.3926 3.76367 12.3775 3.70194C12.4532 3.63744 12.5014 3.54158 12.5014 3.43433C12.5014 3.24012 12.3441 3.08276 12.1499 3.08276H10.2166C10.0224 3.08276 9.86499 3.24012 9.86499 3.43433C9.86499 3.54158 9.91318 3.63748 9.98888 3.70194C9.97379 3.76367 9.96493 3.82784 9.96493 3.89417V5.65555C9.96493 5.72192 9.97383 5.78609 9.98893 5.84787C9.91318 5.91237 9.86499 6.00823 9.86499 6.11553ZM11.5901 3.78584C11.6498 3.78584 11.6984 3.8344 11.6984 3.89417V5.65555C11.6984 5.71526 11.6498 5.76387 11.5901 5.76387H10.7764C10.7167 5.76387 10.6681 5.71531 10.6681 5.65555V3.89417C10.6681 3.83445 10.7167 3.78584 10.7764 3.78584H11.5901Z"
      fill="#E74D3C"
    />
    <path
      d="M13.3138 6.11548C13.3138 6.30969 13.4712 6.46704 13.6654 6.46704H15.5987C15.7929 6.46704 15.9503 6.30969 15.9503 6.11548C15.9503 6.00819 15.9021 5.91228 15.8263 5.84783C15.8414 5.78604 15.8503 5.72187 15.8503 5.65554V3.89417C15.8503 3.82784 15.8414 3.76372 15.8263 3.70198C15.9021 3.63748 15.9503 3.54162 15.9503 3.43433C15.9503 3.24012 15.7929 3.08276 15.5987 3.08276H13.6654C13.4712 3.08276 13.3138 3.24012 13.3138 3.43433C13.3138 3.54158 13.362 3.63748 13.4377 3.70194C13.4226 3.76367 13.4138 3.82784 13.4138 3.89417V5.65554C13.4138 5.72192 13.4227 5.78609 13.4378 5.84787C13.362 5.91228 13.3138 6.00819 13.3138 6.11548ZM15.0389 3.78579C15.0986 3.78579 15.1472 3.83436 15.1472 3.89412V5.6555C15.1472 5.71522 15.0987 5.76383 15.0389 5.76383H14.2252C14.1655 5.76383 14.1169 5.71526 14.1169 5.6555V3.89412C14.1169 3.8344 14.1655 3.78579 14.2252 3.78579H15.0389Z"
      fill="#E74D3C"
    />
    <path
      d="M16.7628 6.11548C16.7628 6.30969 16.9202 6.46704 17.1144 6.46704H19.0477C19.2419 6.46704 19.3993 6.30969 19.3993 6.11548C19.3993 6.00819 19.3511 5.91228 19.2753 5.84783C19.2904 5.78604 19.2993 5.72187 19.2993 5.65554V3.89417C19.2993 3.82784 19.2904 3.76372 19.2753 3.70198C19.3511 3.63748 19.3993 3.54162 19.3993 3.43433C19.3993 3.24012 19.2419 3.08276 19.0477 3.08276H17.1144C16.9202 3.08276 16.7628 3.24012 16.7628 3.43433C16.7628 3.54158 16.811 3.63748 16.8867 3.70194C16.8716 3.76367 16.8628 3.82784 16.8628 3.89417V5.65554C16.8628 5.72192 16.8717 5.78609 16.8868 5.84787C16.811 5.91228 16.7628 6.00819 16.7628 6.11548ZM18.4879 3.78579C18.5476 3.78579 18.5962 3.83436 18.5962 3.89412V5.6555C18.5962 5.71522 18.5476 5.76383 18.4879 5.76383H17.6742C17.6145 5.76383 17.5659 5.71526 17.5659 5.6555V3.89412C17.5659 3.8344 17.6144 3.78579 17.6742 3.78579H18.4879Z"
      fill="#E74D3C"
    />
    <path
      d="M8.474 8.58661V9.75977C8.474 10.1107 8.75951 10.3962 9.11041 10.3962H14.8894C15.2403 10.3962 15.5258 10.1107 15.5258 9.75977V8.58661C15.5258 8.23571 15.2403 7.9502 14.8894 7.9502H9.11041C8.75951 7.9502 8.474 8.23571 8.474 8.58661ZM9.17711 8.65331H14.8227V9.69312H9.17711V8.65331Z"
      fill="#E74D3C"
    />
    <path
      d="M16.4919 8.83467V9.66515C16.4919 9.85936 16.6493 10.0167 16.8435 10.0167C17.0318 10.0167 17.185 9.86864 17.1942 9.68259L17.8051 10.2935C17.8737 10.3621 17.9637 10.3964 18.0537 10.3964C18.1436 10.3964 18.2336 10.3621 18.3023 10.2935C18.4396 10.1561 18.4396 9.93356 18.3023 9.79626L17.6914 9.18539C17.8775 9.17625 18.0256 9.02301 18.0256 8.83472C18.0256 8.64051 17.8682 8.48315 17.674 8.48315H16.8435C16.6493 8.48311 16.4919 8.64051 16.4919 8.83467Z"
      fill="#E74D3C"
    />
    <path
      d="M7.20016 8.29224H5.40283C5.20868 8.29224 5.05127 8.4496 5.05127 8.6438C5.05127 8.838 5.20868 8.99536 5.40283 8.99536H7.20016C7.39432 8.99536 7.55172 8.838 7.55172 8.6438C7.55172 8.4496 7.39432 8.29224 7.20016 8.29224Z"
      fill="#E74D3C"
    />
    <path
      d="M7.20011 9.35156H6.73389C6.53973 9.35156 6.38232 9.50892 6.38232 9.70312C6.38232 9.89733 6.53973 10.0547 6.73389 10.0547H7.20011C7.39426 10.0547 7.55167 9.89733 7.55167 9.70312C7.55167 9.50892 7.39426 9.35156 7.20011 9.35156Z"
      fill="#E74D3C"
    />
  </svg>
);

export default LandingIcon;
