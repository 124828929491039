import React from 'react';

const IOSIcon = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.20564 8.82264C-0.823341 12.3436 0.466522 17.6867 2.71025 20.9265C3.83022 22.5462 4.96412 24.0002 6.5172 24.0002C6.54647 24.0002 6.57582 23.9996 6.60588 23.9984C7.33556 23.9693 7.86285 23.7447 8.37267 23.5276C8.94505 23.2836 9.53703 23.0314 10.4656 23.0314C11.3489 23.0314 11.9126 23.2754 12.4578 23.5113C12.9951 23.7438 13.5485 23.9843 14.3662 23.9689C16.1156 23.9363 17.1897 22.3675 18.1374 20.9834C19.1262 19.5383 19.6223 18.1352 19.7897 17.599L19.7964 17.5777C19.8283 17.4824 19.7833 17.3784 19.6919 17.3364C19.6894 17.3352 19.6801 17.3314 19.6775 17.3303C19.3695 17.2043 16.6672 16.0055 16.6387 12.738C16.6124 10.0834 18.666 8.67762 19.0779 8.42283L19.0971 8.41087C19.1417 8.38214 19.1728 8.33658 19.1833 8.28449C19.1938 8.23248 19.1828 8.17834 19.1529 8.13459C17.7354 6.0602 15.5628 5.74757 14.6881 5.70996C14.5612 5.69729 14.4302 5.69092 14.2986 5.69092C13.2713 5.69092 12.2871 6.07893 11.4965 6.39069C10.9507 6.60591 10.4792 6.79177 10.1541 6.79177C9.78875 6.79177 9.31465 6.60363 8.76564 6.38582C8.03148 6.09443 7.19935 5.76426 6.31843 5.76426C6.29742 5.76426 6.27673 5.76449 6.25634 5.76489C4.20808 5.79502 2.27266 6.96685 1.20564 8.82264Z"
      fill="#E74D3C"
    />
    <path
      d="M14.69 0.000151195C13.4496 0.0506692 11.9617 0.814184 11.0724 1.85586C10.3166 2.73143 9.57824 4.19126 9.77276 5.66273C9.78495 5.7548 9.85987 5.82586 9.95248 5.83302C10.0362 5.83955 10.1217 5.84285 10.2068 5.84293C11.4196 5.84293 12.728 5.17195 13.6215 4.09156C14.5619 2.95073 15.0374 1.48666 14.8936 0.175233C14.8822 0.0724659 14.7918 -0.00386192 14.69 0.000151195Z"
      fill="#E74D3C"
    />
  </svg>
);

export default IOSIcon;
