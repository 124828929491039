import React from 'react';

const PlanIcon = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7501 7.125H10C9.74111 7.125 9.53125 7.33486 9.53125 7.59375C9.53125 7.85264 9.74111 8.0625 10 8.0625H16.7501C17.009 8.0625 17.2188 7.85264 17.2188 7.59375C17.2188 7.33486 17.009 7.125 16.7501 7.125Z"
      fill="#E74D3C"
    />
    <path
      d="M15.1098 9.51234C15.0227 9.42516 14.9017 9.375 14.7784 9.375C14.6552 9.375 14.5342 9.42516 14.447 9.51234C14.3598 9.59953 14.3097 9.72047 14.3097 9.84375C14.3097 9.96703 14.3598 10.088 14.447 10.1752C14.5342 10.2623 14.6552 10.3125 14.7784 10.3125C14.9017 10.3125 15.0227 10.2623 15.1098 10.1752C15.197 10.088 15.2472 9.96703 15.2472 9.84375C15.2472 9.72047 15.197 9.59953 15.1098 9.51234Z"
      fill="#E74D3C"
    />
    <path
      d="M10.331 2.15297C10.2439 2.06578 10.1229 2.01562 9.99963 2.01562C9.87635 2.01562 9.75541 2.06578 9.66823 2.15297C9.58104 2.24016 9.53088 2.36109 9.53088 2.48437C9.53088 2.60766 9.58104 2.72859 9.66823 2.81578C9.75541 2.90297 9.87635 2.95312 9.99963 2.95312C10.1229 2.95312 10.2439 2.90297 10.331 2.81578C10.4182 2.72859 10.4684 2.60766 10.4684 2.48437C10.4684 2.36109 10.4182 2.24016 10.331 2.15297Z"
      fill="#E74D3C"
    />
    <path
      d="M12.8067 9.375H10C9.74111 9.375 9.53125 9.58486 9.53125 9.84375C9.53125 10.1026 9.74111 10.3125 10 10.3125H12.8067C13.0656 10.3125 13.2755 10.1026 13.2755 9.84375C13.2755 9.58486 13.0656 9.375 12.8067 9.375Z"
      fill="#E74D3C"
    />
    <path
      d="M16.7501 12.1875H10C9.74111 12.1875 9.53125 12.3974 9.53125 12.6562C9.53125 12.9151 9.74111 13.125 10 13.125H16.7501C17.009 13.125 17.2188 12.9151 17.2188 12.6562C17.2188 12.3974 17.009 12.1875 16.7501 12.1875Z"
      fill="#E74D3C"
    />
    <path
      d="M15.1098 14.5748C15.0227 14.4877 14.9017 14.4375 14.7784 14.4375C14.6552 14.4375 14.5342 14.4877 14.447 14.5748C14.3598 14.662 14.3097 14.783 14.3097 14.9062C14.3097 15.0295 14.3598 15.1505 14.447 15.2377C14.5342 15.3248 14.6552 15.375 14.7784 15.375C14.9017 15.375 15.0227 15.3248 15.1098 15.2377C15.197 15.1505 15.2472 15.0295 15.2472 14.9062C15.2472 14.783 15.197 14.662 15.1098 14.5748Z"
      fill="#E74D3C"
    />
    <path
      d="M12.8067 14.4375H10C9.74111 14.4375 9.53125 14.6474 9.53125 14.9062C9.53125 15.1651 9.74111 15.375 10 15.375H12.8067C13.0656 15.375 13.2755 15.1651 13.2755 14.9062C13.2755 14.6474 13.0656 14.4375 12.8067 14.4375Z"
      fill="#E74D3C"
    />
    <path
      d="M16.7501 17.25H10C9.74111 17.25 9.53125 17.4599 9.53125 17.7187C9.53125 17.9776 9.74111 18.1875 10 18.1875H16.7501C17.009 18.1875 17.2188 17.9776 17.2188 17.7187C17.2188 17.4599 17.009 17.25 16.7501 17.25Z"
      fill="#E74D3C"
    />
    <path
      d="M15.1098 19.6373C15.0227 19.5502 14.9017 19.5 14.7784 19.5C14.6552 19.5 14.5342 19.5502 14.447 19.6373C14.3598 19.7245 14.3097 19.8455 14.3097 19.9688C14.3097 20.092 14.3598 20.213 14.447 20.3002C14.5342 20.3873 14.6552 20.4375 14.7784 20.4375C14.9017 20.4375 15.0227 20.3873 15.1098 20.3002C15.197 20.213 15.2472 20.092 15.2472 19.9688C15.2472 19.8455 15.197 19.7245 15.1098 19.6373Z"
      fill="#E74D3C"
    />
    <path
      d="M12.8067 19.5H10C9.74111 19.5 9.53125 19.7099 9.53125 19.9688C9.53125 20.2276 9.74111 20.4375 10 20.4375H12.8067C13.0656 20.4375 13.2755 20.2276 13.2755 19.9688C13.2755 19.7099 13.0656 19.5 12.8067 19.5Z"
      fill="#E74D3C"
    />
    <path
      d="M17.6521 1.82817H14.0718C13.5543 1.335 12.8546 1.03125 12.085 1.03125H11.6744C11.3605 0.40978 10.7155 0 10.0001 0C9.2846 0 8.6397 0.40978 8.32573 1.03125H7.91412C7.14448 1.03125 6.44492 1.335 5.92738 1.82817H2.34815C1.08792 1.82817 0.0626221 2.85346 0.0626221 4.1137V21.7145C0.0626221 22.9747 1.08792 24 2.34815 24H17.652C18.9122 24 19.9375 22.9747 19.9375 21.7145V4.1137C19.9376 2.85346 18.9123 1.82817 17.6521 1.82817ZM7.91417 1.96875H8.64476C8.85561 1.96875 9.04048 1.82798 9.09659 1.62478C9.20839 1.22011 9.57992 0.937498 10.0001 0.937498C10.4203 0.937498 10.7918 1.22011 10.9036 1.62478C10.9597 1.82798 11.1446 1.96875 11.3554 1.96875H12.085C13.1024 1.96875 13.9399 2.7539 14.0235 3.75004H5.97571C6.05928 2.7539 6.8968 1.96875 7.91417 1.96875ZM19.0001 21.7145C19.0001 22.4578 18.3954 23.0626 17.6521 23.0626H2.34815C1.60485 23.0626 1.00012 22.4578 1.00012 21.7145V4.1137C1.00012 3.3704 1.60485 2.76567 2.34815 2.76567H5.27066C5.1171 3.11779 5.03136 3.50601 5.03136 3.9141V4.21879C5.03136 4.47768 5.24122 4.68754 5.50011 4.68754H14.4991C14.758 4.68754 14.9679 4.47768 14.9679 4.21879V3.9141C14.9679 3.50606 14.8821 3.11779 14.7286 2.76567H17.6521C18.3954 2.76567 19.0001 3.3704 19.0001 4.1137V21.7145Z"
      fill="#E74D3C"
    />
    <path
      d="M7.01917 7.01229C6.83613 6.82924 6.53931 6.82924 6.35627 7.01224L4.3518 9.01676L3.73432 8.39923C3.55127 8.21623 3.25446 8.21623 3.07137 8.39923C2.88832 8.58228 2.88832 8.87909 3.07137 9.06218L4.02035 10.0112C4.1119 10.1027 4.23185 10.1485 4.3518 10.1485C4.47175 10.1485 4.59175 10.1027 4.68325 10.0112L7.01917 7.67524C7.20222 7.4922 7.20222 7.19538 7.01917 7.01229Z"
      fill="#E74D3C"
    />
    <path
      d="M5.875 17.25H3.625C3.36611 17.25 3.15625 17.4599 3.15625 17.7187V19.9687C3.15625 20.2276 3.36611 20.4375 3.625 20.4375H5.875C6.13389 20.4375 6.34375 20.2276 6.34375 19.9687V17.7187C6.34375 17.4599 6.13389 17.25 5.875 17.25ZM5.40625 19.5H4.09375V18.1875H5.40625V19.5Z"
      fill="#E74D3C"
    />
    <path
      d="M5.875 12.1875H3.625C3.36611 12.1875 3.15625 12.3974 3.15625 12.6562V14.9062C3.15625 15.1651 3.36611 15.375 3.625 15.375H5.875C6.13389 15.375 6.34375 15.1651 6.34375 14.9062V12.6562C6.34375 12.3974 6.13389 12.1875 5.875 12.1875ZM5.40625 14.4375H4.09375V13.125H5.40625V14.4375Z"
      fill="#E74D3C"
    />
  </svg>
);

export default PlanIcon;
